export const useBikeFinder = () => {
    const app = useNuxtApp();
    const cartStore = useCartStore();
    const appStore = useAppStore();
    const route = useRoute();
    const localePath = useLocalePathWoom();
    const { iso2Country } = useLocales();

    // In case we want to exclude the bikefinder in other stores too
    const excludedShops = ['uk'];

    const { hasBike } = storeToRefs(cartStore);
    const { bikeFinderLink, howItWorksLink } = storeToRefs(appStore);

    const bikeFinderLocaleUrl = computed(() => {
        if (!bikeFinderLink.value) return;
        return localePath(bikeFinderLink.value);
    });

    const howItWorksLocaleUrl = computed(() => {
        if (!howItWorksLink.value) return;
        return localePath(howItWorksLink.value);
    });

    const isBikeFinder = computed(() => {
        return route.path === bikeFinderLocaleUrl.value;
    });

    const showTag = computed(() => {
        return !hasBike.value && !isBikeFinder.value && !!bikeFinderLocaleUrl.value && !excludedShops.includes(iso2Country.value.toLowerCase());
    });

    const handleBikeFinder = () => {
        app.$gtm?.pushEvent({
            event: 'bike_finder',
        });
    };

    return {
        showTag,
        bikeFinderLocaleUrl,
        howItWorksLocaleUrl,
        handleBikeFinder,
    };
};
